import {useEffect, useLayoutEffect, useState} from "react";
import { useSelector } from "react-redux";
import styles from './MultiHelp.module.scss'

const MultiHelp = () => {
	const [position, setPosition] = useState({ x: 0, y: 0 });
	const [isVisible, setIsVisible] = useState(false);
	const [tooltipStyle, setTooltipStyle] = useState({});

	const multiHelpData = useSelector(state => state.cursorHelp);

	useEffect(() => {
		const updatePosition = (e) => {
			setPosition({ x: e.clientX, y: e.clientY });
			setIsVisible(true);
		};

		document.addEventListener('mousemove', updatePosition);

		return () => {
			document.removeEventListener('mousemove', updatePosition);
		};
	}, []);

	useLayoutEffect(() => {
		if (isVisible) {
			const tooltip = document.querySelector('#i_multihelp');
			const rect = tooltip.getBoundingClientRect();
			const windowWidth = window.innerWidth;

			let left = position.x + 15;
			let top = position.y + 10;

			if (left + rect.width > windowWidth) {
				left = windowWidth - rect.width;
				top += 10;
			}


			setTooltipStyle({
				position: 'fixed',
				left: left,
				top: top,
			});
		}
	}, [position, isVisible]);

	return (
		<div className={`${styles.multiHelp} ${!multiHelpData.show || !isVisible ? styles.hide : ''}`} style={tooltipStyle}  id={'i_multihelp'}>
			<div className="i_multi-help-content">
				<span>{multiHelpData.content}</span>
			</div>
		</div>
	);
}

export default MultiHelp;
