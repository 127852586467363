import {useEffect, useState} from "react";
import {api} from "../api";
import InputMask from 'react-input-mask';
import {useTranslation} from "react-i18next";
import {ButtonLoader} from "../utils/ui";
import {useSelector} from "react-redux";
import {COUNTRY_CONFIG} from "../utils/config";
import SelectInput from "../pages/main/components/SelectInput";

const SelectRoleModalDealer = () => {

	//Состояния для инпутов
	const [name, setName] = useState('')
	const [bin, setBin] = useState('')
	const [web, setWeb] = useState('')
	const [country, setCountry] = useState('')
	const [phone, setPhone] = useState('')
	const [contact, setContact] = useState('')

	const [error, setError] = useState('')
	const [load, setLoad] = useState(false)
	const [industryList, setIndustryList] = useState(null)
	const [selectedIndustry, setSelectedIndustry] = useState(null)

	//Нужно для перевода
	const {t} = useTranslation();

	// Здесь мы получаем страну сайта
	const siteID = useSelector(state => state.userData.data.country).toLowerCase()

	//Массив с инпутами
	const [formItems, setFormItems] = useState([
		{name: 'name', value: name, placeholder: t("I_SELECT_ROLE_FORM_NAME"), stateFunc: setName},
		{name: 'bin', value: bin, placeholder: t("I_SELECT_ROLE_FORM_BIN"), stateFunc: setBin},
		{name: 'web', value: web, placeholder: t("I_SELECT_ROLE_FORM_WEB"), stateFunc: setWeb},
		// {name: 'country', value: country, placeholder: 'Страна', stateFunc: setCountry},
		{name: 'phone', value: phone, placeholder: t("I_SELECT_ROLE_FORM_PHONE"), stateFunc: setPhone},
		{name: 'contact', value: contact, placeholder: t("I_SELECT_ROLE_FORM_CONTACT"), stateFunc: setContact},
	])

	const checkBoxHandler = (value) => {
		// Создаем копию текущего массива selectedDealers
		const updatedSelectedIndustry = industryList.map((item) => ({
			...item,
			checked: item.id === value,
		}));

		setSelectedIndustry(value)
		setIndustryList(updatedSelectedIndustry);
	};

	//Обработчик изменения инпутов
	const handleInputChange = (e, stateFunc) => {
		setError('')
		const value = e.target.value;
		stateFunc(value);
	};

	//Отправка формы
	const formSubmit = async () => {
		setLoad(true)
		let body = {
			"name": name,
			"bin": bin,
			"web": web,
			"country": siteID.toUpperCase(),
			"phone": phone,
			"contact": contact,
			"industry": selectedIndustry,
		}
		try {
			const result = await api.cabinetApi.addDistribution(body);
			if (result.status === true) {
				window.location.reload()
				setError('')
			} else {
				setError(result.message)
			}

		} catch (error) {
			console.log(error);
		} finally {

			setTimeout(() => {
				setLoad(false)
			}, 700)

		}
	}

	const getData = async () => {
		try {
			const result = await api.cabinetApi.getIndustryList();
			if (result.status === true) {
				setIndustryList(result.industries)
			}
		} catch (error) {
			console.log(error);
		}
	}

	useEffect(() => {
		getData()
	}, []);

	return (
		<div className={`i_select-role-modal`}>
			<div className="i_select-role-modal-title">
				<span>{t("I_SELECT_ROLE_FORM_DISTRIBUTOR")}</span>
			</div>
			<div className="i_select-role-modal-items">
				{formItems.map((item, index) => {
					return (
						<div className="i_select-role-modal-item" key={index}>
							{/*Проверяем какое поле выводим , если BIN то ссылаемся на массив COUNTRY_CONFIG*/}
							<label>{item.stateFunc === setBin ? COUNTRY_CONFIG[siteID].binName : item.placeholder}</label>
							{item.stateFunc === setBin ?
								// Если поле BIN
								<InputMask
									mask={COUNTRY_CONFIG[siteID].bin}
									maskchar={null}
									maskPlaceholder={null}
									alwaysShowMask={false}
									formatchars={{'9': '[0-9]'}}
									// placeholder={t("I_SELECT_ROLE_FORM_BIN")}
									onChange={(e) => {
										handleInputChange(e, item.stateFunc)
									}}
								/>
								// Если поле телефон
								: item.stateFunc === setPhone ?
									<InputMask
										mask={COUNTRY_CONFIG[siteID].phone}
										maskchar={null}
										alwaysShowMask={false}
										formatchars={{'9': '[0-9]'}}
										// placeholder={t("I_SELECT_ROLE_FORM_PHONE")}
										onChange={(e) => {
											handleInputChange(e, item.stateFunc)
										}}
									/>
									:
									// Обычное поле
									<input
										type="text"
										name={item.name}
										// placeholder={item.placeholder}
										value={item.stateFunc === setName ? name : item.stateFunc === setBin ? bin : item.stateFunc === setWeb ? web : item.stateFunc === setCountry ? country : item.stateFunc === setPhone ? phone : contact}
										onChange={(e) => {
											handleInputChange(e, item.stateFunc)
										}}
									/>
							}

						</div>
					);
				})}
				<div className="i_select-role-modal-industry">
					<div className="i_select-role-modal-industry-title">
						<span>Направление деятельности</span>
					</div>
					<div className="i_select-role-modal-industry-items">
						{
							industryList && industryList.map((item, index) => {
								return (
									<SelectInput title={item.name} value={item.id} inputChecked={item.checked} checkBoxValue={checkBoxHandler} key={index} onlyOne={true}/>
								)
							})
						}
					</div>
				</div>
			</div>
			{/*Отправка формы*/}
			<div className="i_select-role-modal-btn" onClick={formSubmit}>
				<ButtonLoader load={load} title={t('I_SELECT_ROLE_FORM_BTN')}/>
			</div>

			{/*Вывод ошибок*/}
			{error !== '' ? <div className={`i_select-role-modal-error`}>
				<span>{error}</span>
			</div> : ''}
		</div>
	)
}

export default SelectRoleModalDealer;