import styles from "./ProductListHead.module.scss";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {ACTION_CONFIG} from "../../config";
import {SelectInput} from "../index";

const ProductListHead = ({columns, store, showCheckBox, actionType}) => {

	const [checkedAllProduct, setCheckedAllProduct] = useState(false);
	const dispatch = useDispatch();
	const products = store.list.products.productList;
	const nextOffset = store.list.products.nextOffset;

	useEffect(() => {
		if(store.list.products.checkedProductId?.length <= 0){
			setCheckedAllProduct(false)
		}
	}, [store.list.products.checkedProductId]);

	// Обработчик для изменения состояний всех чекбоксов
	const checkAllCheckBox = () => {
		setCheckedAllProduct(!checkedAllProduct);
		const updatedSelectedProduct = [...store.list.products.productList].map(productItem => {
			productItem.checked = !checkedAllProduct; // Изменение статуса выделения всех товаров
			return productItem;
		});

		// Обновляем checkedProductList с товарами, которые отмечены
		const selectedItems = updatedSelectedProduct
			.filter(productItem => productItem.checked)
			.map(productItem => productItem.id);

		dispatch({type: ACTION_CONFIG[actionType].updateProductList, products: updatedSelectedProduct, nextOffset: nextOffset});
		dispatch({type: ACTION_CONFIG[actionType].updateCheckedProductId, checkedProductId: selectedItems});
	};


	return(
		<>
			<div className={`${styles.productHead} ${styles[actionType]}`}>
				{showCheckBox && (
					<div className={`${styles.productHeadItem} ${styles.checkbox} ${styles.showMobile} ${products === undefined ? styles.disabled : ''}`}>
						<SelectInput title="" value={0}
						             checkBoxValue={checkAllCheckBox}
						             inputChecked={checkedAllProduct}
						             onlyOne={true}/>
					</div>
				)}
				{columns && columns.map((column, index) => {
					return (
						<div className={`${styles.productHeadItem} ${styles[column.code]}`} key={index}>
							<span>{column.name}</span>
						</div>
					)
				})}
			</div>
		</>
	)
}

export default ProductListHead;