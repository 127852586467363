import {useDispatch} from "react-redux";
import {api} from "../api";
import {useTranslation} from "react-i18next";

const GenerateApiKey = () => {
	const dispatch = useDispatch();
	const {t} = useTranslation();


	const generateApiKey = async () => {
		try{
				const result = await api.cabinetApi.generateApiKey();
				if(result.status === false){
					dispatch({type: "SET_API_KEY", apiKey: t('I_API_KEY_NOT_FOUND')})
				}else if(result.status === true){
					dispatch({type: "SET_API_KEY", apiKey: result.key})
					dispatch({type: "SET_SHOW_API_KEY", showApiKey: true})
					dispatch({type: "SWITCH_POPUP", popupType: null, popupIsOpen: false, currentCatalogItem: null})
					dispatch({type: "SHOW_NOTIFICATION", notificationIsOpen: true, notificationContent: result.message, notificationType: 'success'})
					// setShowApiKey(true)
				}
		}catch (err){
			console.log(err)
		}
	}

	return (
		<div className={'i_delete-distributor-catalog-popup'}>
			<div className="i_popup-title">
				<span>Вы действительно хотите изменить Api-ключ?</span>
			</div>
			<div className="i_popup-buttons">
				<div className="i_popup-button-success" onClick={generateApiKey}>
					<button>Да</button>
				</div>
				<div className="i_popup-button-cancel" onClick={()=>{
					dispatch({type: "SWITCH_POPUP", popupType: null, popupIsOpen: false, currentCatalogItem: null})
				}}>
					<button>Нет</button>
				</div>
			</div>
		</div>
	)
}

export default GenerateApiKey;