import {useSelector} from "react-redux";
import {TOOLS_CONFIG} from "../../utils/config";

const ElementTools = () => {
	// Получаем диспатч и текущий URL


	// Получаем текущий мультитул из стейта
	const currentTools = useSelector(state => state.multiTools.currentTools)


	// Если текущий мультитул есть, то рендерим его
	return (
		<div className={`i_element-tools ${!currentTools ? 'hide' : ''}`}>
			<div className="i_element-tools-left">
				{currentTools && (
					TOOLS_CONFIG.pages[currentTools].elementTools.components.map((Component, index) => {
						return <Component key={index}/>
					})
				)}
			</div>
			<div className="i_element-tools-right">
				{currentTools && (
					TOOLS_CONFIG.pages[currentTools].elementToolsRight.components.map((Component, index) => {
						return <Component key={index}/>
					})
				)}
			</div>
		</div>
	)
}

export default ElementTools;