import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ButtonLoader, CustomInput} from "../../../utils/ui";
import {api} from "../../../api";

const EditDetailQuantityProduct = () => {
	const productDetail = useSelector(state => state.catalog.list.productDetail)
	const {productList, offset, nextOffset, currentCategoryId} = useSelector(state => state.catalog.list.products)

	const dispatch = useDispatch()

	//Состояния для инпутов
	const [quantity, setQuantity] = useState(productDetail?.quantity?.quantity || 0)


	const [btnLoad, setBtnLoad] = useState(false)


	// Отправляем форму для изменения атррибутов товара

	async function formSubmit() {
		setBtnLoad(true)

		try{
			const response = await api.cabinetApi.updateCatalogProductQuantity({id:productDetail.id, quantity: Number(quantity)})
			const updatedProductDetail = await api.cabinetApi.getCatalogProductDetail({id: productDetail.id})

			if(response.status === true){
				// Обновляем детальную информацию о товаре
				dispatch({type: "SET_CATALOG_LIST_PRODUCT_DETAIL", productDetail: updatedProductDetail.product})
				dispatch({type: "SHOW_NOTIFICATION", notificationIsOpen: true, notificationContent: response.message, notificationType: 'success'})
				dispatch({type: "CLOSE_MODAL", modalLevel: 2})

				//Обновляем список товаров в каталоге, чтобы изменения отобразились
				const updatedProductList = productList.map((item) => {
					if(item.id === productDetail.id){
						return {...item,
							quantity: quantity
						}
					}else{
						return item;
					}
				})
				dispatch({type: "SET_CATALOG_LIST_PRODUCTS", products: updatedProductList, nextOffset: nextOffset, offset: offset, currentCategoryId: currentCategoryId})
			}else{
				dispatch({type: "SHOW_NOTIFICATION", notificationIsOpen: true, notificationContent: response.message, notificationType: 'failed'})
			}

		}catch (e){
			console.log(e)
		}
		setBtnLoad(false)
	}


	return (
		<>
			{/* Инпуты */}
			<CustomInput title={'Остаток'} value={quantity} onChange={setQuantity} type={'number'}/>

			{/*Кнопка*/}
			<ButtonLoader title={'Сохранить'} load={btnLoad} onClick={formSubmit}/>
		</>
	)
}

export default EditDetailQuantityProduct;