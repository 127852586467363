import {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import styles from './SelectCatalogListItem.module.scss';
import {ScrollBox} from "../index"; // Импорт SCSS-модуля

const Category = ({category, categories, modalLevel, containerID}) => {
	const subCategories = categories.filter(subCategory => subCategory.parent_id === category.id);
	const dispatch = useDispatch();
	const level = category.depth_level;
	const currentCatalogItem = useSelector(state => state.multiModal.modals[modalLevel-1].currentCategoryId);

	const hasScrolled = useRef(false); // Флаг для отслеживания выполнения скролла

	const changeCurrentCategory = (item) => {
		if (item.id === currentCatalogItem) {
			dispatch({type: 'SET_MODAL_CURRENT_CATEGORY_ID', modalLevel: modalLevel, currentCategoryId: null});
			dispatch({type: 'SET_MODAL_CURRENT_CATEGORY_NAME', modalLevel: modalLevel, currentCategoryName: null})
		} else {
			dispatch({type: 'SET_MODAL_CURRENT_CATEGORY_ID', modalLevel: modalLevel, currentCategoryId: item.id});
			dispatch({type: 'SET_MODAL_CURRENT_CATEGORY_NAME', modalLevel: modalLevel, currentCategoryName: item.name})
		}
	};

	function changeSelectedItem(id) {
		let updatedItem = categories.map((item) => {
			item.is_active = item.id === id;
			if (item.is_active) {
				changeCurrentCategory(item);
			}
			return item;
		});

		dispatch({
			type: 'SET_MODAL_CATEGORY_LIST',
			modalLevel: modalLevel-1,
			categoryList: updatedItem,
		});
	}

	// Прокрутка категории к активному элементу
	setTimeout(() => {
		const parentCategory = document.querySelector(`#${containerID} > div`);
		const activeCategory = document.querySelector(`#${containerID} #activeCategory`);

		if (activeCategory && parentCategory && !hasScrolled.current) {
			const parentRect = parentCategory.getBoundingClientRect();
			const activeRect = activeCategory.getBoundingClientRect();

			// Рассчитываем смещение activeCategory относительно верхней границы контейнера
			const offset = activeRect.top - parentRect.top + parentCategory.scrollTop;

			// Прокручиваем контейнер
			parentCategory.scrollTo({
				top: offset,
			});

			hasScrolled.current = true;
		}
	}, 600);


	return (
		<div
			className={`${styles.catalogCategoryItem} ${styles[`catalogCategoryItemLevel${level}`]}`}
			key={category.id}
			data-id={category.id}
			style={{paddingLeft: level === 0 ? '15px' : '30px'}}
			id={currentCatalogItem === category.id ? 'activeCategory' : null}
		>
			<div className={styles.catalogCategoryItemTitle} onClick={() => changeSelectedItem(category.id)} id={'selectCatalogItemTitle'}>
				<div className={`${styles.catalogTitleCircle} ${currentCatalogItem === category.id ? styles.active : ''}`}></div>
				<span>{category.name}</span>
			</div>
			{subCategories.map(subCategory => (
				category.id !== 0 && <Category key={subCategory.id} category={subCategory} categories={categories} modalLevel={modalLevel} containerID={containerID}/>
			))}
		</div>
	);
};

const SelectCatalogListItem = ({categories, modalLevel, containerID}) => {
	const rootCategories = categories.filter(
		(category) =>
			(category.parent_id === 0 || category.id === 0) &&
			categories.findIndex((cat) => cat.id === category.id) === categories.indexOf(category)
	);

	return (
		<div className={`${styles.modalCategoryListContent}`} id={containerID}>
			<ScrollBox>
				{rootCategories.map(category => (
					<Category key={category.id} category={category} categories={categories} modalLevel={modalLevel} containerID={containerID}/>
				))}
			</ScrollBox>
		</div>
	);
};

export default SelectCatalogListItem;
