import {useSelector} from "react-redux";
import AddMarkupElement from "../multitools/components/AddMarkupElement";
import AddCatalogDistributorElement from "../multitools/components/AddCatalogDistributorElement";


const AddElementModal = () => {
	// Redux методы
	const currentTools = useSelector(state => state.multiTools.currentTools)

	return (
		<>
			{currentTools === "markups-my-catalog" && <AddMarkupElement/>}
			{currentTools === "distributor-catalogs-inner" && <AddCatalogDistributorElement/>}
		</>
	);
}

export default AddElementModal