import styles from "../../../pages/main/components/Profile/Profile.module.scss";
import {api} from "../../../api";
import {useDispatch} from "react-redux";
import {useRef} from "react";

export default function ProfileMore({profileHeight}){
	// Переменные
	const dispatch = useDispatch()
	const profileMoreRef = useRef(null)

	profileHeight(profileMoreRef.current?.clientHeight)

	//Метод для выхода пользователя
	const logoutUser = async () =>{
		try{
			const result = await api.authApi.logoutUser();
			if(result.status === true){
				const result = await api.authApi.logoutUser();
				if(result.status === true){
					window.location.reload();
				}
			}
		}catch (e) {
			console.log(e)
		}
	}

	// Метод для открытия модального окна смены пароля
	function openSetAccountPasswordModal(){
		dispatch({type: "OPEN_MODAL", modalType: "setAccountPasswordModal", modalLevel: 1})
	}

	// Массив с данными для отображения
	const moreData = [{func: openSetAccountPasswordModal, name:"Смена пароля"}, {func: logoutUser, name:"Выход"}]

	return (
		<div className={styles.profileMore} ref={profileMoreRef}>
			{moreData.map((item, index) => {
				return (
					<div className={styles.profileMoreItem} onClick={item.func} key={index}>
						<span className={styles.dotted}></span>
						<span className={styles.profileValue}>{item.name}</span>
					</div>
				)
			})}
		</div>
	)
}