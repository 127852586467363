import {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddListItemButton, ButtonLoader, DoubleInput } from "../../../utils/ui";
import { api } from "../../../api";
import {ReactComponent as CloseSvg} from "../../../images/closeModal.svg";
import styles from '../EditDetailProductModal.module.scss'

const EditDetailAttributeProduct = () => {
	const productDetail = useSelector((state) => state.catalog.list.productDetail);

	const dispatch = useDispatch();

	// Состояние для хранения данных о свойствах товара
	const [detailProps, setDetailProps] = useState(productDetail.props !== null ? productDetail.props.props !== null ? productDetail.props.props: [{name: '', value: ''}] : [{name: '', value: ''}]); // Начинаем с пустого массива

	useEffect(() => {
		console.log(detailProps)
	}, [detailProps]);

	// Функция для добавления нового элемента в состояние
	const addDetailProp = () => {
		setDetailProps((prevProps) => [
			...prevProps,
			{ name: "", value: "" },
		]);
	};

	// Функция для обновления значения конкретного элемента
	const updateDetailProp = (index, name, value) => {
		const updatedProps = [...detailProps];
		updatedProps[index] = { name, value }; // Обновляем нужный элемент по индексу
		setDetailProps(updatedProps);
	};

	// Функция для удаления элемента по индексу
	const removeDetailProp = (index) => {
		if(detailProps.length === 1) {
			setDetailProps([{name: '', value: ''}])
		}else{
			const updatedProps = detailProps.filter((_, i) => i !== index); // Убираем элемент по индексу
			setDetailProps(updatedProps);
		}
	};

	// Отправляем форму для изменения атрибутов товара
	async function formSubmit() {
		try{
			const response = await api.cabinetApi.updateCatalogProductProp({id : productDetail.id, props: detailProps});
			const updatedProductDetail = await api.cabinetApi.getCatalogProductDetail({id: productDetail.id})

			if(response.status === true){
				dispatch({type: "SET_CATALOG_LIST_PRODUCT_DETAIL", productDetail: updatedProductDetail.product})
				dispatch({type: "SHOW_NOTIFICATION", notificationIsOpen: true, notificationContent: response.message, notificationType: 'success'})
				dispatch({type: "CLOSE_MODAL", modalLevel: 2})
			}else{
				dispatch({type: "SHOW_NOTIFICATION", notificationIsOpen: true, notificationContent: response.message, notificationType: 'failed'})
			}
		}catch (e){
			console.log(e)
		}
	}

	return (
		<>
			{/* Инпуты для ввода данных */}
			{detailProps.map((prop, index) => (
				<div className={styles.modalItem}>
					<DoubleInput
						key={index}
						index={index} // Передаем индекс для обновления конкретного элемента
						name={prop.name}
						value={prop.value}
						onChange={updateDetailProp} // Обработчик для обновления состояния
						firstInputTitle={"Название свойства"}
						secondInputTitle={"Значение свойства"}
					/>
					<div className={styles.modalItemDelete} onClick={() => {
						removeDetailProp(index)
					}}>
						<CloseSvg/>
					</div>
				</div>

			))}

			{/* Кнопка добавления новой пары */}
			<AddListItemButton onClick={addDetailProp}/>

			{/* Кнопка отправки формы */}
			<ButtonLoader title={"Сохранить"} load={false} onClick={formSubmit} />
		</>
	);
};

export default EditDetailAttributeProduct;
