import selectSvg from '../../../../images/select-button.svg'
import {ReactSVG} from "react-svg";
import styles from './SelectButton.module.scss'

const SelectButton = ({title, onClick}) => {
	return (
		<button className={styles.selectButton} onClick={onClick}>
			<ReactSVG src={`${selectSvg}`} alt="select" className={styles.selectButtonSvg}/>
			<span>{title}</span>
		</button>
	)
}

export default SelectButton