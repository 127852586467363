import closeBtn from "../../../images/catalogs/category/add-category.svg";
import styles from './AddListItemButton.module.scss'

const AddListItemButton = ({onClick}) => {
	return(
		<>
			<div className={styles.addListItemButton} onClick={onClick}>
				<div className={styles.addListItemButtonImg}>
					<img src={`${closeBtn}`} alt="img"/>
				</div>
			</div>
		</>
	)
}

export default AddListItemButton;