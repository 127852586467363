const initialState = {
	profileData: {},
	apiKey: null,
	showApiKey: false
}

export const profileReducer = (state = initialState, action) => {
	switch (action.type) {
		case "GET_PROFILE_DATA":
			return {
				...state,
				profileData: action.profileData
			};
		case "SET_API_KEY":
			return {
				...state,
				apiKey: action.apiKey
			};
		case "SET_SHOW_API_KEY":
			return {
				...state,
				showApiKey: action.showApiKey
			}
		default:
			return state;
	}
}