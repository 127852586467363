import {useState} from "react";
import styles from './SetAccountPasswordModal.module.scss';

import {ScrollBox, ButtonLoader, PasswordInput} from "../../utils/ui";
import {api} from "../../api";
import {useDispatch} from "react-redux";

const SetAccountPasswordModal = () => {
	// Состояния для инпутов
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [btnLoad, setBtnLoad] = useState(false);
	const [error, setError] = useState('');
	const dispatch = useDispatch();

	async function sendForm(){
		setBtnLoad(true);
		if(password !== confirmPassword){
			setError('Пароли не совпадают');
			setBtnLoad(false);
			return false;
		}else {
			const response = await api.cabinetApi.setAccountPassword({password: password, confirm: confirmPassword});
			if (response.status === true) {
				setBtnLoad(false);
				dispatch({type: "CLOSE_MODAL", modalLevel: 1});
				dispatch({
					type: "SHOW_NOTIFICATION",
					notificationIsOpen: true,
					notificationContent: response.message,
					notificationType: 'success'
				})
			} else {
				setError(response.message);
				setBtnLoad(false);
			}
		}
	}

	return (
		<div className={styles.modal}>
			<div className={styles.modalTitle}>
				<span>Смена пароля</span>
			</div>
			<ScrollBox>
				<div className={styles.modalItems}>
					<PasswordInput title={'Новый пароль'} value={password} onChange={setPassword}/>
					<PasswordInput title={'Повторите пароль'} value={confirmPassword} onChange={setConfirmPassword}/>
				</div>
			</ScrollBox>
			<ButtonLoader title={'Изменить'} load={btnLoad} onClick={sendForm}/>
			{error && <div className={styles.modalError}>{error}</div>}
		</div>
	);
};

export default SetAccountPasswordModal;
