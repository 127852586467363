import AddCategory from "../multitools/dealer/catalogPage/AddCategory";
import EditCategory from "../multitools/dealer/catalogPage/EditCategory";
import DeleteCategory from "../multitools/dealer/catalogPage/DeleteCategory";
import AttachCategory from "../multitools/dealer/catalogPage/AttachCategory";
import AddElement from "../multitools/dealer/catalogPage/AddElement";
import DeleteElement from "../multitools/dealer/catalogPage/DeleteElement";
import AddEmail from "../multitools/distributor/AddEmail";
import NavigateButton from "../multitools/components/NavigateButton/NavigateButton";
import ShowActiveElement from "../multitools/dealer/catalogPage/ShowActiveElement";

const currentDomain = window.location.hostname;
const domainSegments = currentDomain.split('.');
const lastSegment = domainSegments.pop();

export const API_CABINET_URL = `${lastSegment === 'd' ? 'http' : 'https'}://api.apicore.${lastSegment}/cabinet`
export const API_URL = `${lastSegment === 'd' ? 'http' : 'https'}://api.apicore.${lastSegment}`

// Конфигурация для мульти-тулзов

export const TOOLS_CONFIG = {
	"pages" : {
		"catalogs-my-inner" : {
			"catalogTools" : {
				"components" : [AddCategory, EditCategory, DeleteCategory],
			},
			"multiTools" : {
				"components" : [],
				// "components" : [NavigateButton],
			},
			"elementTools" : {
				"components" : [AddElement, DeleteElement],
			},
			"elementToolsRight" : {
				"components" : [],
			}
		},
		"catalogs-distributor-inner":{
			"catalogTools" : {
				"components" : [],
			},
			"multiTools" : {
				"components" : [],
			},
			"elementTools" : {
				"components" : [],
			},
			"elementToolsRight" : {
				"components" : [],
			}
		},
		"catalogs-source-inner"  : {
			"catalogTools" : {
				"components" : [AddCategory, EditCategory, DeleteCategory],
			},
			"multiTools" : {
				"components" : [],
			},
			"elementTools" : {
				"components" : [AttachCategory, DeleteElement],
			},
			"elementToolsRight" : {
				"components" : [],
			}
		},
		"distributor-catalogs-inner" : {
			"catalogTools" : {
				"components" : [AddCategory, EditCategory, DeleteCategory],
			},
			"multiTools" : {
				"components" : [],
			},
			"elementTools" : {
				"components" : [AddElement, AttachCategory, DeleteElement],
			},
			"elementToolsRight" : {
				"components" : [ShowActiveElement],
			}
		},
		"markups-my-catalog": {
			"catalogTools" : {
				"components" : [],
			},
			"multiTools" : {
				// "components" : [NavigateButton],
				"components" : [],
			},
			"elementTools" : {
				"components" : [AddElement, DeleteElement],
			},
			"elementToolsRight" : {
				"components" : [],
			}
		},
		"dealer-page": {
			"catalogTools" : {
				"components" : [],
			},
			"multiTools" : {
				"components" : [AddEmail],
			},
			"elementTools" : {
				"components" : [],
			},
			"elementToolsRight" : {
				"components" : [],
			}
		}
	}
}

// Конфигурация для action
export const ACTION_CONFIG = {
	markups:{
		updateProductList: "SET_MARKUPS_LIST_PRODUCTS",
		updateCheckedProductId: "SET_MARKUPS_SELECTED_PRODUCT_ID",
	},
	catalogs:{
		updateProductList: "SET_CATALOG_LIST_PRODUCTS",
		updateProductListMore: "SET_CATALOG_LIST_PRODUCTS_MORE",
		updateCheckedProductId: "SET_CATALOG_SELECTED_PRODUCT_ID",
		setCurrentCategoryID: "SET_CATALOG_CURRENT_CATEGORY_ID",
		setCurrentCategoryName: "SET_CATALOG_CURRENT_CATEGORY_NAME",
		setCategoryList: "SET_CATALOG_CATEGORY_LIST",
	}
}


// Конфигурация для стран
export const COUNTRY_CONFIG = {
	'kz': {phone: '+7 (999)-999-99-99', bin: '999999999999', binName: 'БИН'},
	'uz': {phone: '+\\9\\98 (99)-999-99-99', bin: '999999999', binName: 'ИНН'},
	'kg': {phone: '+\\9\\96 (999)-999-999', bin: '99999999999999', binName: 'БИН'},
}




