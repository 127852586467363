import styles from './CategoryListModal.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {SelectCatalogListItem, SearchHighlight} from "../../../../utils/ui";
import {useEffect, useState} from "react";
import {ReactComponent as EditSvg} from "./../../../../images/catalogs/category/edit.svg";


const CategoryListModal = ({showSearch, closed, modalLevel}) => {
	const dispatch = useDispatch();
	const parentCategory = useSelector(state => state.catalog.categoryList.categoryList);
	const categories = useSelector(state => state.multiModal.modals[1].categoryList);
	const {category_id, category_name} = useSelector(state => state.catalog.list.productDetail);
	const randNumber = Math.floor(Math.random() * 1000);
	const currentCategoryName = useSelector(state => state.multiModal.modals[1].currentCategoryName)

	const containerID = `selectCategoryList_${randNumber}`;

	const [closeCategory, setCloseCategory] = useState(closed)

	// Изменение состояния открытия/закрытия категорий
	const handleChangeCloseCategory = () => {
		setCloseCategory(!closeCategory)
	}

	// Передача данных в модальное окно
	useEffect(() => {
		dispatch({
			type: 'SET_MODAL_CATEGORY_LIST',
			modalLevel: modalLevel,
			categoryList: parentCategory,
		});

		dispatch({
			type: 'SET_MODAL_CURRENT_CATEGORY_ID',
			modalLevel: modalLevel,
			currentCategoryId: category_id,
		});

		dispatch({
			type: 'SET_MODAL_CURRENT_CATEGORY_NAME',
			modalLevel: 2,
			currentCategoryName: category_name,
		});

	}, [dispatch, parentCategory]);



	return (
		<>
			<div className={`${styles.modalCategoryList} ${closed ? closeCategory ? styles.closed : styles.open : ''}`}>

				<div className={styles.modalCategoryListTitle}>
					<span>Категория</span>
				</div>

				<div className={styles.modalCategoryListContainer}>
					{/*Если параметр скрытия есть , то добавляем возможность скрития и показа списка категорий*/}
					{closed &&
						<div className={styles.modalCategoryCurrent}>
							<span>{currentCategoryName || 'Корневой раздел'}</span>

							<div className={styles.modalCategoryCurrentEdit} onClick={handleChangeCloseCategory}>
								<EditSvg/>
							</div>
						</div>
					}

					{/*Показываем поиск*/}
					{showSearch &&
						<SearchHighlight containerID={`#${containerID} #selectCatalogItemTitle`}/>
					}

					<SelectCatalogListItem categories={categories} type={'modal'} actionType={'modal'} modalLevel={2} editList={true} containerID={containerID}/>
				</div>


			</div>
		</>
	)
}

export default CategoryListModal;