import styles from './ProductActiveStatus.module.scss'
import {useDispatch} from "react-redux";

const ProductActiveStatus = ({ active }) => {

	const dispatch = useDispatch()

	return (
			<div className={`${styles.itemCircle} ${active ? styles.active : ''}`}
			     onMouseEnter={() => dispatch({type: "SWITCH_CURSOR_HELP", show: true, content: active ? 'Активен' : 'Неактивен'})}
			     onMouseLeave={() => dispatch({type: "SWITCH_CURSOR_HELP", show: false})
			}></div>
	)
}

export default ProductActiveStatus