import closeProfile from "../../../../images/closeModal.svg";
import avatar from "../../../../images/avatar.svg";
import {useEffect, useLayoutEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import styles from './Profile.module.scss'
import {ProfileMore} from "../../../../utils/ui/";

const Profile = () => {
	const userData = useSelector(state => state.userData.data)

	const [profileActive, setProfileActive] = useState(false)
	const [profileHeight, setProfileHeight] = useState(null)
	const [profileWidth, setProfileWidth] = useState(null)

	const profileHeadRef = useRef(null)
	const profileRef = useRef(null)

	useLayoutEffect(() => {
		calcHeightAndWidth()
	}, []);

	// Функция для изменения состояния активности профиля
	const handleChangeActive = (bool) => {
		setProfileActive(bool);

		bool ? calcHeightAndWidth() : setProfileHeight(null);
	}

	useEffect(() => {
		// Добавляем слушатель события при монтировании компонента
		window.addEventListener('resize', calcHeightAndWidth);

		// Убираем слушатель при размонтировании компонента
		return () => {
			window.removeEventListener('resize', calcHeightAndWidth);
		};
	},[])

	// Функция для вычисления высоты и ширины блока
	function calcHeightAndWidth(){
		if(profileHeadRef.current){
			setTimeout(()=>{
				const blockWidth = profileHeadRef.current.clientWidth;
				setProfileWidth(blockWidth);
			}, 200)
		}
	}

	// useEffect для обработки клика за пределами компонента
	useEffect(() => {
		const handleOutsideClick = (event) => {
			if (profileRef.current && !profileRef.current.contains(event.target)) {
				setProfileActive(false);
			}
		};

		document.addEventListener('click', handleOutsideClick);

		return () => {
			document.removeEventListener('click', handleOutsideClick);
		};
	}, []);

	// Закрытие модального окна по нажатию на Escape
	const handleKeyDown = (event) => {
		if (event.key === 'Escape') {
			setProfileActive(false);
		}
	};

	useEffect(() => {
		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, []);


	return (
		<div className={`${styles.headerItem} ${styles.profile} ${profileActive ? styles.active : ''}`} style={{
			minWidth: `${profileWidth + 35}px`
		}} ref={profileRef}>
			<div className={styles.headerItemProfile} style={{
				height: `${profileActive ? profileHeight + 65 : 36}px`,
				borderRadius: `${profileActive ? 15 : 80}px`,
				minWidth: `${profileActive ? profileWidth + 75 : profileWidth + 25}px`
			}}>
				<div className={styles.profileHead}>
					<div className={styles.profileLeft} onClick={() => {
						handleChangeActive(true);
					}} ref={profileHeadRef}>
						<img src={avatar} alt="avatar"/>
						<span>{userData?.user.login}</span>
					</div>

					<div className={styles.profileClose} onClick={() => {
						handleChangeActive(false);
					}}>
						<img src={closeProfile} alt="close"/>
					</div>
				</div>

				<ProfileMore profileHeight={(e)=>{setProfileHeight(e)}}/>
			</div>
		</div>
	)
}

export default Profile;