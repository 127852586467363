import ImagesUploader from "./components/ImagesUploader";
import {api} from "../api";
import {useEffect, useState} from "react";
import closeBtn from '../images/closeModal.svg'
import {useDispatch, useSelector} from "react-redux";
import {ReactSVG} from "react-svg";
import infoImg from "../images/info.svg";
import {ButtonLoader} from "../utils/ui";
const EditProductImagesModal = () => {
	// Получаем ID и название продукта из Store
	const productID = useSelector(state => state.multiModal.modals[1].modalData.productId);
	const productName = useSelector(state => state.multiModal.modals[1].modalData.productName);
	const parentModal = useSelector(state => state.multiModal.modals[0]);

	// Состояния для хранения изображений
	const [manualsImages, setManualsImages] = useState([]); // Изображения, добавленные вручную
	const [apiImages, setApiImages] = useState([]); // Изображения, добавленные через API
	const [load, setLoad] = useState(false); // Состояние загрузки для кнопки
	const [deletedImages, setDeletedImages] = useState([]); // Список удаленных изображений

	const dispatch = useDispatch(); // Используем dispatch для работы с Redux
	const uploadImage = useSelector(state => state.uploadImage); // Данные о загруженных изображениях

	// Функция для получения списка изображений продукта
	const getData = async () => {
		const response = await api.cabinetApi.getProductImagesList({id: productID}); // Запрос на получение изображений продукта
		const manualsImages = response.product?.images.types?.manual; // Изображения, добавленные вручную
		const apiImages = response.product?.images.types?.api; // Изображения, добавленные через API
		setManualsImages(manualsImages); // Устанавливаем изображения вручную
		setApiImages(apiImages); // Устанавливаем изображения через API
	}

	// Функция для удаления загруженного изображения (из состояния)
	const deleteUploadedItem = (item) => {
		const newImages = uploadImage.uploadedImages.filter(image => image.data !== item.data); // Фильтруем удаленное изображение
		dispatch({type: "SET_UPLOADED_IMAGES", uploadedImages: newImages}); // Обновляем состояние в Redux
	}

	// Функция для удаления изображения, добавленного через API
	const deleteApiImages = ({id}) => {
		setDeletedImages(prevState => Array.isArray(prevState) ? [...prevState, id] : [id]); // Добавляем id удаленного изображения в список
		setApiImages(apiImages.filter(image => image.id !== id)); // Обновляем список изображений API
	}

	// Функция для удаления изображений, добавленных вручную
	const deleteManualsImages = ({id}) => {
		setDeletedImages(prevState => Array.isArray(prevState) ? [...prevState, id] : [id]); // Добавляем id удаленного изображения
		setManualsImages(manualsImages.filter(image => image.id !== id)); // Обновляем список изображений, добавленных вручную
	}

	// Используем useEffect для получения данных при загрузке компонента
	useEffect(() => {
		getData(); // Загружаем данные о продукте
	}, []);

	// Функция для сохранения изменений
	const saveImages = async () => {
		setLoad(true)

		let formData = new FormData(); // Создаем объект FormData для отправки данных

		const Files = uploadImage?.uploadedImages?.map(item => item.file); // Получаем файлы из состояния Redux

		if(Files && Files.length > 0){
			Files.forEach((file, index) => {
				formData.append(`image[${index}]`, file); // Каждый файл добавляем по отдельности
			});
		}

		if(deletedImages && deletedImages.length > 0){
			deletedImages.forEach((id, index) => {
				formData.append(`delete[${index}]`, id); // Добавляем список удаленных изображений
			});
		}

		// Добавляем данные в formData
		formData.append('id', productID); // Добавляем ID продукта

		// Отправляем запрос на сохранение изображений
		const response = await api.cabinetApi.saveProductImages(formData);

		if(response.status === true){
			setDeletedImages([]); // Очищаем список удаленных изображений
			dispatch({type: "SET_UPLOADED_IMAGES", uploadedImages: null}); // Очищаем список загруженных изображений

			getData(); // После успешного сохранения обновляем данные
			dispatch({type: "SHOW_NOTIFICATION", notificationIsOpen: true, notificationContent: response.message, notificationType: 'success'})

			if(parentModal.modalType === "distributorCatalogProductDetailModal"){
				const productDetail = await api.cabinetApi.getCatalogProductDetail({id: productID});
				dispatch({type: "SET_MODAL_DATA", modalLevel: 1, modalData: {productId : productID, productDetail: productDetail.product}})
			}
			setLoad(false)
		}
	}


	return (
		<div className='i_edit-product-images'>
			<div className="i_edit-product-images-title">
				<span>{productName}</span>
			</div>

			<div className="i_edit-product-images-content">
				<div className="i_edit-product-images-manuals">
					<div className="i_edit-product-images-content-title">
						<span>Картинки добавленные вручную</span>
					</div>

					<div className="i_edit-product-images-items">
						{manualsImages && manualsImages.map((item, index) => {
							return (
								<div className="i_edit-product-images-item" key={index}>
									<div className="i_edit-product-images-delete" onClick={() => {
										deleteManualsImages(item)
									}}><ReactSVG src={`${closeBtn}`}/></div>
									<img src={item.path} alt="manual"/>
								</div>
							)
						})}
						{uploadImage.uploadedImages && uploadImage.uploadedImages.map((item, index) => {
							return (
								<div className="i_edit-product-images-item" key={index}>
									<div className="i_edit-product-images-delete" onClick={() => {
										deleteUploadedItem(item)
									}}><ReactSVG src={`${closeBtn}`}/></div>
									<img src={item.data} alt="manual"/>
								</div>
							)
						})}
					</div>

					<ImagesUploader/>

				</div>
				<div className="i_edit-product-images-apis">
					<div className="i_edit-product-images-content-title">
						<span>Картинки добавленные через API</span>
					</div>
					<div className="i_edit-product-images-items">
						{apiImages && apiImages.map((item, index) => {
							return (
								<div className="i_edit-product-images-item" key={index}>
									<div className="i_edit-product-images-delete" onClick={() => {
										deleteApiImages(item)
									}}><ReactSVG src={`${closeBtn}`}/></div>
									<img src={item.path} alt="api"/>
								</div>
							)
						})}
						{
							!apiImages && (
								<div className="i_edit-product-images-empty">
									<div className="i_edit-product-images-empty-title">
										<img src={infoImg} alt=""/>
										<span>Картинки отсутствуют.</span>
									</div>
									<span>Вы можете добавить картинки через&nbsp;
										<a href="https://docs.apicore.kz/distributor-api/"
										   target="_blank">Distributor API</a>
									</span>
								</div>
							)
						}
					</div>
				</div>
				<div className="i_edit-product-images-save">
					<ButtonLoader load={load} title={'Сохранить'} onClick={saveImages}/>
				</div>
			</div>

		</div>
	)
}

export default EditProductImagesModal;