import {useEffect, useRef, useState} from "react";
import {api} from "../../../api";
import {Link, useHref} from "react-router-dom";
import {ReactSVG} from 'react-svg';
import logo from '../../../images/logo/logo_horizontal.svg';
import closeBtn from '../../../images/burger-language-arrow.svg';
import circleLogo from '../../../images/logo/logo_circle.svg';
import {useTranslation} from "react-i18next";
import i18n from "../../../i18n";
import useLocalStorage from "../../../hooks/useLocalStorage";
import {useSelector, useDispatch} from "react-redux";
import telegramLogo from '../../../images/sidebar/telegram.svg';
import useWindowWidth from "../../../utils/useWindowWidth";

const Sidebar = () => {
	// Нужно для перевода
	const {t} = useTranslation();

	// Храним состояния элементов
	const [sidebarItem, setSidebarItem] = useState(null);
	const [catalogActive, setCatalogActive] = useState(false)
	const [catalogItemHeight, setCatalogItemHeight] = useState(null)

	// Записываем и достаем данные из локального хранилища
	const [showSidebar, setShowSidebar] = useLocalStorage('showSidebar', true)
	const [language, setLanguage] = useLocalStorage('language', 'ru')
	const [currentLink, setCurrentLink] = useLocalStorage('currentLink', null);
	const windowWidth = useWindowWidth()

	useEffect(() => {
		if(windowWidth < 1300){
			setShowSidebar(false)
		}
	}, [windowWidth]);

	const userData = useSelector(state => state.userData.data)
	const dispatch = useDispatch();

	const subItemsRef = useRef(null)

	// Получаем текущий путь
	const currentPath = useHref();


	useEffect(() => {
		// Вычисляем высоту подэлементов для анимации "Каталогов"
			calcHeightAndWidth()
	}, [catalogActive, showSidebar])


	// Функция для изменения состояния активности профиля
	function calcHeightAndWidth(){
		if (subItemsRef.current) {
			setCatalogItemHeight(subItemsRef.current.clientHeight)
		}
	}

	// Функция перерасчета высоты блока при изменении размера окна
	useEffect(() => {
		// Добавляем слушатель события при монтировании компонента
		window.addEventListener('resize', calcHeightAndWidth);

		// Убираем слушатель при размонтировании компонента
		return () => {
			window.removeEventListener('resize', calcHeightAndWidth);
		};
	},[])

	//Показываем подсказку при наведении на элемент
	const showSidebarHelp = (title) => {
		dispatch({type: "SWITCH_CURSOR_HELP", show: true, content: title})
	}

	// Изменяем язык
	const setCurrentLanguage = (el) => {
		i18n.changeLanguage(el)
		setLanguage(el)
		window.location.reload();
	}

	// Асинхронная функция получения меню с сервера
	const getMenu = async () => {
		try {
			const result = await api.cabinetApi.getMenu();
			dispatch({type: "SET_MENU", menu: result.menu})
			setSidebarItem(result.menu);
		} catch (error) {
			console.log(error);
		}
	};

	// Получаем меню с сервера при загрузке страницы
	useEffect(() => {
		getMenu();
	}, []);

	// Функция для активации ссылки
	const handleLinkClick = (item, title) => {
		if (!item.items) {
			setCurrentLink(item);
			setCatalogActive(false)
		}
	};


	return (
		<div className={`i_sidebar ${showSidebar ? '' : 'close'}`}>
			<div className="i_sidebar-close" onClick={() => {
				setShowSidebar(!showSidebar)
			}}>
				<img src={closeBtn} alt=""/>
			</div>
			<div className="i_sidebar-upper">

				{/*Логотип*/}

				<div className="i_sidebar-logo">
					<Link to={'/'}>
						<img src={`${showSidebar ? logo : circleLogo}`} alt="logo"
						     onClick={() => handleLinkClick(sidebarItem[0], t("I_PERSONAL_AREA"))}/>
					</Link>
				</div>

				{/*Ссылка в sidebar*/}

				<div className={'i_sidebar-items'}>
					{sidebarItem &&
						sidebarItem.map((item, index) => {
							// Проверяем активна ли ссылка
							let linkClassName = currentPath === item.link || currentPath.startsWith(`${item.link}/`) ? 'active' : '';

							if (item.items) {
								item.items.forEach(subItem => {
									//Если находимися в категории каталога то показываем active catalog в sidebar
									if (currentPath === subItem.link || currentPath.startsWith(`${subItem.link}/`)) {
										linkClassName = 'active'
										if (catalogActive === false) {
											setCatalogActive(true)
										}
									}
								})
							}

							return (
								<div key={index}
								     className={`i_sidebar-item ${linkClassName} ${item.name === 'Каталоги' && userData.entity.type === "dealer" ? 'dealer-catalogs' : ''} ${item.name === 'Каталоги' && catalogActive ? 'active' : ''}`}
								     style={{
									     height: item.name === 'Каталоги' && catalogActive ? `${70 + catalogItemHeight}px` : '50px',
								     }}
								     onMouseEnter={() => !showSidebar && showSidebarHelp(item.name)}
								     onMouseLeave={() => !showSidebar && dispatch({type: "SWITCH_CURSOR_HELP", show: false})}
								>
									{
										// Если есть подэлементы то показываем их
										item.items ?
											<>
												<a onClick={() => setCatalogActive(!catalogActive)}>
													<ReactSVG src={item.icon} className={'i_sidebar-item-svg'}/>
													<span>{item.name}</span>
												</a>
												<div className={'i_sidebar-subitems'} ref={subItemsRef}>
													{item.items.map((subItem, subIndex) => {
														/*Если мы находимся в странице категории каталога то показываем active catalog в sidebar*/
														if(currentPath === subItem.link || currentPath.startsWith(subItem.link)){
															if(!catalogActive){
																setCatalogActive(true);
															}
														}
														return (
															<div
																className={`i_sidebar-subitem ${currentPath === subItem.link || currentPath.startsWith(subItem.link) ? 'active' : ''}`}
																key={subIndex}
																onMouseEnter={() => !showSidebar && showSidebarHelp(subItem.name)}
																onMouseLeave={() => !showSidebar && showSidebarHelp(item.name)}>
																<Link to={`${subItem.link}`}
																      onClick={() => handleLinkClick(subItem, subItem.title)}>
																	<div className="dotted"></div>
																	<span>{showSidebar ? subItem.name : subItem.name.charAt(0)}</span>
																</Link>
															</div>
														)
													})}
												</div>
											</>
											:
											<>
												{/*Если нет подълементов то показываем просто элементы*/}
												<Link to={`${item.link}`}
												      onClick={() => handleLinkClick(item, item.title)}>
													<ReactSVG src={item.icon} className={'i_sidebar-item-svg'}/>
													<span>{item.name}</span>
												</Link>
											</>


									}
								</div>
							);
						})
					}
				</div>
			</div>

			{/*Нижняя часть sidebar*/}
			<div className="i_sidebar-bottom">
				<div className="i_sidebar-items">
					{userData.entity.type === 'distributor' ? (
						<div className="i_sidebar-item-doc">
							Документация <a
							href="https://docs.apicore.kz/distributor-api/"
							target="_blank">Distributor API</a>
						</div>
					) : (
						<div className="i_sidebar-item-doc">
							Документация <a
							href="https://docs.apicore.kz/dealer-api/"
							target="_blank">Dealer API</a>
						</div>
					)}

					<div className="i_sidebar-item-telegram">
						<ReactSVG src={telegramLogo}/>
						{userData.entity.type === 'distributor' ? (
							<a href="https://t.me/apicore_distributor" target="_blank">
								Дистрибьюторам
							</a>
						) : (
							<a href="https://t.me/apicore_dealer" target="_blank">
								Дилерам
							</a>
						)}
					</div>

					{/*<LanguageSelector setCurrentLanguage={setCurrentLanguage} language={language} showTitle={showSidebar} inSidebar={true}/>*/}
				</div>
			</div>
		</div>
	);
};

export default Sidebar;
