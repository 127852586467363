import circleLogo from '../../../images/logo/logo_circle.svg'
import ripples from '../../../images/tube-spinner.svg'
import styles from './Loader.module.scss'

const Loader = ({show}) =>{
	return(
		<div className={`${styles.loader} ${show ? '' : styles.hide}`} >
			<img src={ripples} alt="ripples" className={styles.loaderRipples}/>
			<img src={circleLogo} alt="logo" className={styles.loaderLogo}/>
		</div>
	)
}

export default Loader;