import {useEffect, useState} from "react";
import styles from './SwitchButton.module.scss'

const SwitchButton = ({value, checkBoxValue ,inputChecked, title, size}) => {

	const [isChecked, setIsChecked] = useState(inputChecked);

	// При изменении inputChecked меняем isChecked
	useEffect(() => {
		setIsChecked(inputChecked);
	}, [inputChecked]);

	const handleInputChange = () => {
		// Обновляем состояние isChecked при изменении чекбокса
		const newCheckedValue = !isChecked;
		setIsChecked(newCheckedValue);

		// Если новое значение isChecked равно true, выводим значение в консоль
		checkBoxValue({value:value, isChecked: newCheckedValue});
	};


	return(
		<div className={`${styles.switchBtn} ${styles[size]}`}>
			<div className={styles.switch}>
				<input type="checkbox"
				       checked={isChecked}
				       onChange={handleInputChange}
				       value={value}
				/>
				<span className={`${styles.slider} ${styles.round}`}></span>
			</div>
			{title && (
				<div>
					<span>{title}</span>
				</div>
			)}
		</div>
	)
}

export default SwitchButton;