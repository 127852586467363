import { ButtonLoader, TextEditor } from "../../../utils/ui";
import {useState} from "react";

const EditDetailDescriptionProduct = () => {

	const [data, setData] = useState();

	const getEditorData = (editorData) => {
		setData(editorData);
	}

	const formSubmit = () => {
		// Получаем данные из редактора через getEditorData
		console.log(data)
	}

	return (
		<>
			<TextEditor getEditorData={getEditorData} />

			{/* Кнопка отправки формы */}
			<ButtonLoader title={"Сохранить"} load={false} onClick={formSubmit} />
		</>
	)
}

export default EditDetailDescriptionProduct;
