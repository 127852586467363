import {useEffect, useState} from "react";
import { api } from "../api";
import { useDispatch, useSelector } from "react-redux";
import {ButtonLoader, CustomInput, SwitchButton} from "../utils/ui";
import {COUNTRY_CONFIG} from "../utils/config";

const EditProfileModal = () => {
	const dispatch = useDispatch();

	//Состояния для инпут форм
	const [name, setName] = useState('');
	const [bin, setBin] = useState('');
	const [phone, setPhone] = useState('');
	const [contact, setContact] = useState('');
	const [web, setWeb] = useState('');
	const [hide, setHide] = useState('');
	const [load, setLoad] = useState(false);
	const [loadData, setLoadData] = useState(true);

	const [error, setError] = useState('')

	const role = useSelector(state => state.userData.data.entity.type)
	const siteID = useSelector(state => state.userData.data.country).toLowerCase()
	// const siteID = 'kg'

	//Достаем данные
	const getData = async () =>{
		try{
			const result = await api.cabinetApi.getProfile();
			if(role === 'distributor') {
				if (result.status === true) {
					setName(result.distributor.name);
					setBin(result.distributor.bin);
					setPhone(result.distributor.phone);
					setContact(result.distributor.contact);
					setWeb(result.distributor.web);
					setHide(result.distributor.hide);
				}
			}else{
				if (result.status === true) {
					setName(result.dealers.name);
					setBin(result.dealers.bin);
					setPhone(result.dealers.phone);
					setContact(result.dealers.contact);
				}
			}

		}catch (e){
			console.log(e);
		}finally {
			setLoadData(false);
		}
	}


	useEffect(() =>{
		getData();
	},[])



	//Отправка формы
	const sendForm = async () => {
		setLoad(true);
		try{

			let body;

			if(role === 'distributor'){
				body = {
					name: name,
					phone: phone,
					contact: contact,
					web: web,
					bin: bin,
					hide: hide
				}
			}else{
				body = {
					name: name,
					phone: phone,
					contact: contact,
					bin: bin
				}
			}

			const resultEditProfile = await api.cabinetApi.editProfile(body);


			if(resultEditProfile.status === true){

				dispatch({type: "OPEN_MODAL", modalType: "editProfileModal", modalLevel: 1})

				const resultDataProfile = await api.cabinetApi.getProfile();

				if(resultDataProfile.status === true){
					if(role === 'distributor'){
						dispatch({type: "GET_PROFILE_DATA", profileData: resultDataProfile.distributor})
					}else{
						dispatch({type: "GET_PROFILE_DATA", profileData: resultDataProfile.dealers})
					}
					setLoad(false)
				}
				setError('')

			}else{
				setError(resultEditProfile.message)
				setLoad(false)
			}

		}catch (e) {
			console.log(e)
		}
	}

	// Меняем Hide из switchCheckbox
	const checkboxHandler = (data) => {
		setHide(data.isChecked)
	}

	return (
		<div className={'i_edit-profile-modal'}>
			<div className="i_edit-profile-title">
				<span>Редактирование профиля</span>
			</div>
			{!loadData && (
				<>
					<div className="i_edit-profile-items">
						<CustomInput title={'Название компании'} value={name} onChange={(e) => {
							setName(e)
						}}/>
						<CustomInput title={'Телефон'} value={phone} onChange={(e) => {
							setPhone(e)
						}} isMobile={true}/>
						<CustomInput title={'Контактное лицо'} value={contact} onChange={(e) => {
							setContact(e)
						}}/>
						{role === 'distributor' && <CustomInput title={'Cайт'} value={web} onChange={(e) => {
							setWeb(e)
						}}/>}
						<CustomInput title={COUNTRY_CONFIG[siteID].binName} value={bin} onChange={(e) => {
							setBin(e)
						}} isBin={true}/>
						{role === 'distributor' &&
							<>
								<div className="i_edit-profile-item">
									<SwitchButton title={'Скрыть в списке дистрибьюторов'} inputChecked={hide} checkBoxValue={checkboxHandler} value={''}/>
								</div>
							</>
						}

					</div>
					<div className="i_edit-profile-btn">
						<ButtonLoader title={'Сохранить'} load={load} onClick={sendForm}/>
					</div>
				</>
			)}

			{error !== '' ?
				<div className={`i_modal-error`}>
					<span>{error}</span>
				</div> : ''
			}

		</div>
	);
};

export default EditProfileModal;
