import {useSelector} from "react-redux";
import EditDetailAttributeProduct from "./components/EditDetailAttributeProduct";
import styles from './EditDetailProductModal.module.scss'
import {ScrollBox} from "../../utils/ui";
import EditDetailPropsProduct from "./components/EditDetailPropsProduct";
import {useEffect, useState} from "react";
import EditDetailDescriptionProduct from "./components/EditDetailDescriptionProduct";
import EditDetailParamsProduct from "./components/EditDetailParamsProduct";
import EditDetailQuantityProduct from "./components/EditDetailQuanityProduct";
import EditDetailPriceProduct from "./components/EditDetailPriceProduct";

const EditDetailProductModal = () => {

	const {type} = useSelector(state => state.multiModal.modals[1].modalData);

	const [modalTitle, setModalTitle] = useState('Изменение товара');

	useEffect(() => {
		switch (type){
			case 'attributes':
				setModalTitle('Изменение товара');
				break;
			case 'props':
				setModalTitle('Изменение свойств');
				break;
			case 'description':
				setModalTitle('Изменение описания');
				break;
			case 'params':
				setModalTitle('Изменение параметров');
				break;
			case 'quantity':
				setModalTitle('Изменение остатка');
				break;
			case 'price':
				setModalTitle('Изменение цен');
				break;
			default:
				setModalTitle('Изменение товара')
		}
	}, []);

	return(
		<div className={styles.modal}>
			<div className={styles.modalTitle}>
				<span>{modalTitle}</span>
			</div>
			<ScrollBox>
				<div className={styles.modalContent}>
					{type === 'attributes' && <EditDetailAttributeProduct/>}
					{type === 'props' && <EditDetailPropsProduct/>}
					{type === 'description' && <EditDetailDescriptionProduct />}
					{type === 'params' && <EditDetailParamsProduct />}
					{type === 'quantity' && <EditDetailQuantityProduct />}
					{type === 'price' && <EditDetailPriceProduct />}
				</div>
			</ScrollBox>
		</div>
	)
}

export default EditDetailProductModal;