import {CustomInput} from "../../index";
import styles from './PasswordInput.module.scss';
import {ReactSVG} from "react-svg";
import {ReactComponent as EyeOpen} from "../../../../images/eye.svg";
import {ReactComponent as EyeClose} from "../../../../images/eye-close.svg";
import {useState} from "react";

const PasswordInput = ({ value, onChange , title}) => {

	const [showVisible, setShowVisible] = useState(false)

	return(
		<div className={styles.passwordInput}>
			<CustomInput title={title} type={showVisible ? 'text' : 'password'} onChange={onChange} value={value}/>
			<div className={styles.changeVisible} onClick={()=>{setShowVisible(!showVisible)}}>
				{
					showVisible ? <EyeOpen/> : <EyeClose/>
				}
			</div>
		</div>
	)
};

export default PasswordInput;