import closeImg from '../../../images/closeModal.svg'
import {ReactSVG} from "react-svg";
import {useSelector} from "react-redux";
import {useDispatch} from "react-redux";
import {useEffect} from "react";
import styles from './Notification.module.scss'

const Notification = () => {
	const dispatch = useDispatch();
	const notification = useSelector(state => state.notification)
	const closeNotification = () =>{
		dispatch({type: "SHOW_NOTIFICATION", notificationIsOpen: false, notificationContent: notification.notificationContent, notificationType: null})
	}

	useEffect(()=>{
		if(notification.notificationIsOpen){
			setTimeout(() => {
				dispatch({type: "SHOW_NOTIFICATION", notificationIsOpen: false, notificationContent: notification.notificationContent, notificationType: null})
			}, 4000)
		}
	},[notification.notificationIsOpen])

	return(
		<>
			<div className={`${styles.notification} ${notification.notificationIsOpen ? styles.show : styles.hide} ${notification.notificationType === 'success' ? styles.success : styles.failed}`}>
				<div className={styles.notificationContent}>
					<div className={styles.notificationContentText}>
						<span>{notification.notificationContent}</span>
					</div>
					<ReactSVG src={`${closeImg}`} className={styles.notificationClose} onClick={closeNotification}/>
				</div>
			</div>
		</>
	)
}

export default Notification;