import styles from "./DoubleInput.module.scss";
import {CustomInput} from "../index";
import {useState, useEffect} from "react";

const DoubleInput = ({
	                     index,
	                     name,
	                     value,
	                     onChange,
	                     firstInputTitle,
	                     secondInputTitle,
	                     firstInputWidth,
	                     firstInputConfig,
	                     secondInputWidth,
	                     secondInputConfig
                     }) => {
	const [inputName, setInputName] = useState(name);
	const [inputValue, setInputValue] = useState(value);

	// Флаг для отслеживания изменений, чтобы избежать лишних ререндеров
	const [isChanged, setIsChanged] = useState(false);

	// Используем useEffect, чтобы избежать бесконечного цикла обновлений
	useEffect(() => {
		if (isChanged) {
			onChange(index, inputName, inputValue);
			setIsChanged(false); // сбрасываем флаг после обновления
		}
	}, [isChanged, inputName, inputValue, index, onChange]);

	useEffect(() => {
		setInputName(name);
		setInputValue(value);
	}, [name, value]);

	const handleNameChange = (e) => {
		const newName = e;
		setInputName(newName);
		setIsChanged(true); // Помечаем, что значение изменилось
	};

	const handleValueChange = (e) => {
		const newValue = e;
		setInputValue(newValue);
		setIsChanged(true); // Помечаем, что значение изменилось
	};

	return (
		<div className={styles.doubleInputs}>
			<div className={styles.doubleInput} style={{width: firstInputWidth || '49%'}}>
				<CustomInput
					title={firstInputTitle}
					value={inputName}
					onChange={handleNameChange}
					{...firstInputConfig}
				/>
			</div>
			<div className={styles.doubleInput} style={{width: secondInputWidth || '49%'}}>
				<CustomInput
					title={secondInputTitle}
					value={inputValue}
					onChange={handleValueChange}
					{...secondInputConfig}
				/>
			</div>
		</div>
	);
};

export default DoubleInput;
