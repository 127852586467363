import { useState, useRef } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ru } from "date-fns/locale";
import { format } from "date-fns";
import { ReactComponent as CalendarSvg } from "../../../images/calendar.svg";

// Регистрация русской локали
registerLocale("ru", ru);

function DateTimePicker({ title, onChange }) {
	const [selectedDate, setSelectedDate] = useState(null);
	const datePickerRef = useRef(null); // Создаем ref для DatePicker

	// Отправляем значение в родительский компонент
	const onChangeDate = (date) => {
		setSelectedDate(date);
		onChange(format(date, "dd.MM.yyyy HH:mm", { locale: ru }));
	};

	// Открываем календарь при клике на иконку
	const handleIconClick = () => {
		datePickerRef.current.setOpen(true);
	};

	return (
		<div className="i_date-picker">
			<label>{title}</label>
			<div className="date-picker-wrapper">
				<DatePicker
					ref={datePickerRef}                     // Привязываем ref к DatePicker
					selected={selectedDate}
					onChange={onChangeDate}
					showTimeSelect
					showYearDropdown
					dateFormat="d MMMM, yyyy h:mm"
					placeholderText="Выберите дату и время"
					timeCaption="Время"
					locale="ru"
					timeFormat="HH:mm"
					timeIntervals={15}
					yearDropdownItemNumber={15}
					scrollableYearDropdown
				/>
				<div onClick={handleIconClick} className={'date-picker-svg'}>
					<CalendarSvg />
				</div>
			</div>
		</div>
	);
}

export default DateTimePicker;
