import {ReactSVG} from "react-svg";
import plus from '../../../images/catalogs/category/add-category.svg'
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";

const AddElement = () => {

	const dispatch = useDispatch();
	const multiTools = useSelector(state => state.multiTools);
	const [helpTitle, setHelpTitle] = useState('')

	useEffect(() => {
		switch (multiTools.currentTools) {
			case "distributor-catalogs-inner": {
				setHelpTitle('Добавить товар')
				break;
			}
			case "markups-my-catalog" : {
				setHelpTitle('Добавить наценку')
				break;
			}
			default: {
				setHelpTitle('Добавить элемент')
			}
		}
	}, []);


	return(
		<div className={`i_element-tools-item`}
		     onMouseEnter={() => dispatch({type: "SWITCH_CURSOR_HELP", show: true, content: helpTitle})}
		     onMouseLeave={() => dispatch({type: "SWITCH_CURSOR_HELP", show: false})}
		     onClick={() => dispatch({type: "OPEN_MODAL", modalType: 'addElementModal', modalLevel: 1})}
		>
			<ReactSVG src={`${plus}`} className={'i_element-tools-item-svg'}/>
		</div>
	)
}

export default AddElement;