import {ReactSVG} from "react-svg";
import deleteSvg from '../../../images/catalogs/category/delete.svg'
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";

const DeleteElement = () => {
	const dispatch = useDispatch();
	const currentTools = useSelector(state => state.multiTools.currentTools)

	// Вызываем оба useSelector вне условия
	const checkedProductId = useSelector(state => state.markups.list.checkedProductId);
	const currentCategoryId = useSelector(state => state.catalog.list.products.checkedProductId);

	const multiTools = useSelector(state => state.multiTools);
	const [helpTitle, setHelpTitle] = useState('')

	useEffect(() => {
		switch (multiTools.currentTools) {
			case "distributor-catalogs-inner": {
				setHelpTitle('Удалить товар')
				break;
			}
			case "markups-my-catalog" : {
				setHelpTitle('Удалить наценку')
				break;
			}
			default: {
				setHelpTitle('Удалить элемент')
			}
		}
	}, []);

	// Определяем currentId уже после вызова хуков
	const currentId = currentTools === 'markups-my-catalog' ? checkedProductId : currentCategoryId;

	return(
		<div className={`i_element-tools-item ${!currentId || currentId.length === 0 ? 'disabled' : ''}`}
		     onMouseEnter={() => dispatch({type: "SWITCH_CURSOR_HELP", show: true, content: helpTitle})}
		     onMouseLeave={() => dispatch({type: "SWITCH_CURSOR_HELP", show: false})}
		     onClick={() => dispatch({type:'SWITCH_POPUP', popupType: 'deleteElement', popupIsOpen: true})}
		>
			<ReactSVG src={`${deleteSvg}`} className={'i_element-tools-item-svg'}/>
		</div>
	)
}

export default DeleteElement;